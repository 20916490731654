import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { getFetchTranslations } from './api';
import { ELEMENT_KEY, ELEMENT_VAR, TRANSLATE_URL } from './constants';
import { I18nInstance, I18nOptions } from './types';
import { getNamespaceFromUrl } from './utils';

// @ts-ignore
const mode = import.meta.env.MODE;
const isDevelopment = mode === 'development';

type CreateI18nType = {
  variables?: Record<string, any>;
  async?: boolean;
} & I18nOptions;

let translations: Record<string, any> = {};
let isTranslationsLoaded = false;

let t = (i18nKey: string): string => {
  throw new Error(`createI18n needs to be initialized before using t(${i18nKey})`);
};

// 개행을 <br />로 치환하는 함수
const replaceNewlineWithBr = (text: string): string => {
  return text.replace(/\n/g, '<br />');
};

function createI18n(params: any): Promise<I18nInstance> | I18nInstance {
  if (!params) {
    params = {};
  }

  const {
    observerTarget = document.body,
    variables = {}, // 외부에서 주입할 변수
    async = true
  }: CreateI18nType = params;

  // 인터폴레이션을 처리하는 함수
  const interpolate = (template: string, vars: Record<string, any>): string => {
    const interpolatedText = template
      .replace(/<<(\w+)>>/g, (_, key) => {
        return vars[key] || '';
      })
      .trimStart()
      .trimEnd();

    return replaceNewlineWithBr(interpolatedText); // 개행을 <br />로 치환
  };

  const interpolateElement = (_element: HTMLElement, template: string, vars: Record<string, any>): string => {
    const interpolatedText = template
      .replace(/<<(\w+)>>/g, (_, key) => {
        const i18nValue = _element.getAttribute(`${ELEMENT_VAR}${key}`);
        if (i18nValue) {
          return i18nValue;
        }
        return vars[key] || '';
      })
      .trimStart()
      .trimEnd();

    return replaceNewlineWithBr(interpolatedText); // 개행을 <br />로 치환
  };

  const applyTranslationsToElement = (element: HTMLElement) => {
    const i18nKey = element.getAttribute(ELEMENT_KEY);
    if (i18nKey) {
      const translation = get(translations, i18nKey, element.textContent);
      if (translation) {
        element.innerHTML = interpolateElement(element, translation, variables); // 주입된 변수 사용
      }
    }
  };

  const applyTranslations = () => {
    const elements = observerTarget.querySelectorAll<HTMLElement>(`[${ELEMENT_KEY}]`);
    elements.forEach((element: HTMLElement) => applyTranslationsToElement(element));
  };

  const observer = new MutationObserver(
    debounce((mutations: MutationRecord[]) => {
      mutations.forEach((mutation) => {
        const { type, addedNodes, target, attributeName } = mutation;

        if (type === 'childList') {
          addedNodes.forEach((node) => {
            if (node.nodeType === Node.ELEMENT_NODE) {
              const element = node as HTMLElement;
              applyTranslationsToElement(element);
              element.querySelectorAll<HTMLElement>('[data-i18n]').forEach(applyTranslationsToElement);
            }
          });
        } else if (type === 'attributes' && target instanceof HTMLElement) {
          if (target.hasAttribute('data-i18n')) {
            applyTranslationsToElement(target);
          } else if (attributeName && attributeName.startsWith('data-i18n-')) {
            applyTranslationsToElement(target);
          }
        }
      });
    }, 100)
  );

  observer.observe(observerTarget, {
    childList: true,
    subtree: true,
    attributes: true
  });

  const updateVariables = (newVariables: Record<string, any>) => {
    Object.assign(variables, newVariables); // 기존 변수에 새로운 값을 덮어씀
    if (!isTranslationsLoaded) {
      throw new Error(`translation.json is Not Loaded in updateVariables`);
    }
    applyTranslations(); // UI 업데이트
  };

  const disconnectObserver = () => {
    observer.disconnect();
  };

  // getFetchTranslations(`${TRANSLATE_URL}${isDevelopment && '/dev'}/${getNamespaceFromUrl()}/translation.json`)
  //   .then((data) => {
  //     translations = data; // 번역 데이터를 저장
  //     isTranslationsLoaded = true;
  //     applyTranslations();
  //   })
  //   .catch((error) => {
  //     console.error('Error fetching translations:', error);
  //   });

  // t 함수는 번역 데이터를 기반으로 동작
  t = (i18nKey: string): string => {
    if (!isTranslationsLoaded) {
      throw new Error('translation.json is Not Loaded');
    }
    const translation = get(translations, i18nKey);
    console.log('translation', translation);
    if (translation === undefined) {
      return i18nKey;
    }
    return interpolate(translation, variables);
  };

  const jsonUrl = `${TRANSLATE_URL}${isDevelopment && '/dev'}/${getNamespaceFromUrl()}/translation.json`;

  if (async) {
    getFetchTranslations(jsonUrl).then((data) => {
      translations = data; // 번역 데이터를 저장
      isTranslationsLoaded = true;
      applyTranslations();
    });

    return { applyTranslations, updateVariables, disconnectObserver, t };
  }

  return getFetchTranslations(jsonUrl)
    .then((data) => {
      translations = data; // 번역 데이터를 저장
      isTranslationsLoaded = true;
      applyTranslations();
      return { applyTranslations, updateVariables, disconnectObserver, t };
    })
    .catch((error) => {
      console.error('Error fetching translations:', error);
      return { applyTranslations, updateVariables, disconnectObserver, t };
    });
}

export { createI18n, t };
