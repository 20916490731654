import { LANGUAGE } from './constants';

function getNamespaceFromUrl(): string {
  const pathname = window.location.pathname; // 현재 URL의 경로
  const pathSegments = pathname.split('/').filter((segment) => segment.length > 0);

  if (pathSegments.length < 2) {
    return LANGUAGE.KO;
  }
  const languageValues: string[] = Object.values(LANGUAGE);
  const namespace = pathSegments[1]; // 두 번째 세그먼트가 네임스페이스
  if (!languageValues.includes(namespace)) {
    return LANGUAGE.KO;
  }

  return namespace;
}

export { getNamespaceFromUrl };
