// 번역 파일을 가져오는 함수
async function getFetchTranslations(url: string): Promise<Record<string, any>> {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch translations from ${url}: ${response.statusText}`);
  }
  return await response.json();
}

export { getFetchTranslations };
