import { Union } from '@tkl-packages/models';

export const TRANSLATE_URL = `https://tketlink.dn.toastoven.net/frontend/locales`;

export const LANGUAGE = {
  KO: 'ko',
  EN: 'en',
  ZH: 'zh',
  JA: 'ja'
} as const;
export type LanguageType = Union<typeof LANGUAGE>;

export const ELEMENT_KEY = 'data-i18n';
export const ELEMENT_VAR = 'data-i18n-';
